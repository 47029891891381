<template>
  <sd-cards :title="$t('layout.shipmentRequest.history')">
    <versions-table resourceName="user" :resourceId="userId"
    />
  </sd-cards>
</template>

<script>
import {
  computed, defineComponent
} from 'vue';
import {useRouter} from 'vue-router';
import VersionsTable from '@/components/shipments/VersionsTable';
import {useStore} from 'vuex';

export default defineComponent({
  components: {VersionsTable},
  setup() {
    const {
      state
    } = useStore();
    const router = useRouter();
    const userId = computed(() => router.currentRoute.value.params.userId === 'me' ? state.session.currentUser._jv.id : router.currentRoute.value.params.userId);

    return {
      userId
    };
  }
});
</script>

<style lang="scss" scoped>
.ant-switch-disabled {
    opacity: 1 !important;
}
</style>
